import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'isomorphic-fetch';

const graphqlUri =
  process.env.GATSBY_GRAPHQL_MODE === 'production'
    ? process.env.GATSBY_GRAPHQL_URI_PRODUCTION
    : process.env.GATSBY_GRAPHQL_URI_STAGING;

const link = createUploadLink({
  uri: graphqlUri,
  fetch,
  fetchOptions: {
    'Access-Control-Allow-Origin': '*',
  },
  credentials: 'include',
  headers: {
    authorization: '',
  },
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
